import React from "react";
import Layout from "./Layout";

export default () => (
  <Layout>
    <div>
      <strong>404</strong> – Oh no!
      <p>Is anything missing? Ping me on Twitter.</p>
    </div>
  </Layout>
);
